// gray color variables
$white: #fff !default;
$gray-100: #f1f2f4 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #66737f !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #22262a !default;
$black: #000 !default;

// color variables
$blue: #0038A8 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #CE1126 !default;
$orange: #fd7e14 !default;
$orange-tint:#F5F5FA !default;
$yellow: #FCD116 !default;
$green: #8bc34a !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
$dark-blue: #324667 !default;
// theme colors
$primary: $black !default;
$secondary: $white !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

// Custom Dark mode colors
$dark-primary: $white !default;
$dark-secondary: $black !default;

// Sizes
$small-font-size: .875em !default;
$table-striped-bg-factor: .02;
$modal-dialog-margin: 1rem !default;

// Shadow and gradients
$enable-shadows: true;
$enable-gradients: true;
$gradient: linear-gradient(90deg, rgba($white, .15), rgba($white, 0)) !default;
